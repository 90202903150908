import React, { useContext, useState } from 'react'
import './HomeNave.scss'
import { LiaTableSolid } from "react-icons/lia";
import { CgChevronDoubleRightR } from "react-icons/cg";
import { BiSupport } from "react-icons/bi";
import { RiCloseCircleLine } from "react-icons/ri";
import { RiLogoutBoxLine } from "react-icons/ri";

import { FaBars } from "react-icons/fa";

import logo from '../../Assets/Images/New_logo_2.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { IoHomeOutline } from "react-icons/io5";

import { useCookies } from 'react-cookie';
import { ChartContext } from '../../Context/Charts';
import axios from 'axios';
import { BaseUrl } from '../../API/ApiHandle';



export default function HomeNave() {
    const [Hide, setHide] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate()
    const [token, , removeCookie] = useCookies(['token']);
    const { TableId } = useContext(ChartContext);

    const Logout = async () => {
        await axios.get(`${BaseUrl}/logout`, {
            headers: {
                Authorization: `Bearer ${token.token}`
            }
        })
            .then((res) => {
                localStorage.clear();
                if (res.data.Done) {
                    removeCookie('token', { path: '/' });
                    localStorage.clear()
                    navigate('/Login')
                }
            })
            .catch((err) => console.log(err))

    }

    const handleHide = () => {
        setHide(!Hide)
    }

    const HandleNavigate = (Link) => {
        navigate(Link);
        setHide(false)
    }
    return <>
        <div className={Hide ? "dropback apper-dropback" : "dropback "} onClick={handleHide}>
        </div>
        <aside className={Hide ? 'allnav  py-24 px-7' : 'allnav py-24 px-7 apper'} >

            <div>
                <img className="Logo-colaps" src={logo} loading='lazy' alt='Logo' />
                <div className="h-[300px] flex flex-col gap-y-1 mt-9">
                    <p onClick={() => HandleNavigate('/')} className={pathname === "/" ? 'Active' : ''}><IoHomeOutline className='icon' /></p>
                    <p onClick={() => HandleNavigate(`/Tables/${TableId}`)} className={pathname === `/Tables/${TableId}` ? 'Active' : ''}><LiaTableSolid className='icon' /></p>
                    <p onClick={() => HandleNavigate('/Visualize')} className={pathname === "/Visualize" ? 'Active' : ''}><CgChevronDoubleRightR className='icon' /></p>
                    <p onClick={() => HandleNavigate('/Support')} className={pathname === "/Support" ? 'Active' : ''}><BiSupport className='icon' /></p>
                </div>

            </div>

            <RiLogoutBoxLine className='icon Logout cursor-pointer text-white' onClick={Logout} />

        </aside >

        <span className='bars' onClick={handleHide}>
            {Hide ? <RiCloseCircleLine /> : <FaBars />}
        </span>

    </>
}
